














































































































































































































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import EventsMixin from '@/mixins/events-mixin';
import { Component } from 'vue-property-decorator';
import PrintOptions from '../prints/PrintOptions.vue';
import CommonUtils from '@/utils/common-utils';
import SearchFilter from '@/components/core/SearchFilter.vue';

@Component({
  mixins: [EventsMixin],
  components: {
    BaseToolbar,
    PrintOptions,
    SearchFilter
  }
})
export default class EventsToolbar extends BaseToolbar {
  editMode!: boolean;
  searchText!: string;
  selectedRows!: Array<any>;
  showImportEvents!: boolean;
  showExportEvents!: boolean;
  hasSelectedRows!: boolean;
  searchWidthGrow = '0';
  filterSelectionsShowEventTypes!: Array<any>;
  selectedEventType!: string;

  $refs!: {
    printOptions: PrintOptions,
    searchFilter: SearchFilter
  };

  get localEventsComponent() {
    return this.currentPageInstance || {};
  }

  get scrollToToday() {
    return this.localEventsComponent.scrollToToday || this.emptyFunction;
  }

  get editEvent() {
    return this.localEventsComponent.editEvent || this.emptyFunction;
  }

  get toggleEditMode() {
    return this.localEventsComponent.toggleEditMode || this.emptyFunction;
  }

  get deleteEvents() {
    return this.localEventsComponent.deleteEvents || this.emptyFunction;
  }

  get localEventTypeItems() {
    return this.localEventsComponent.localEventTypeItems || [];
  }

  get arrayAddOrRemove() {
    return CommonUtils.arrayAddOrRemove;
  }

  focusMenu() {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          (this.$refs as any).firstMenuItem.$el.focus();
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    });
  }

  focusButton() {
    this.$refs.searchFilter.focusButton();
  }

  clearFilter() {
    this.filterSelectionsShowEventTypes = this.localEventTypeItems.map((i: any) => i.value);
    this.searchText = '';
  }

  get isFiltered() {
    if (CommonUtils.hasNoText(this.searchText) && this.filterSelectionsShowEventTypes.length === this.localEventTypeItems.length) {
      this.searchWidthGrow = '0';
      return false;
    } else {
      this.searchWidthGrow = '40';
      return true;
    }
  }

  get filterCount() {
    var count = 0;
    if (CommonUtils.hasText(this.searchText)) {
      count += 1;
    }
    count += (this.localEventTypeItems.length - this.filterSelectionsShowEventTypes.length);
    return count;
  }

  selectEventType(val: string) {
    if (val === 'all') {
      this.filterSelectionsShowEventTypes = this.localEventTypeItems.map((i: any) => i.value);
    } else if (val === 'none') {
      this.filterSelectionsShowEventTypes = [];
    }
    this.selectedEventType = val;
  }

  get schoolItems() {
    return this.localEventsComponent.schoolItems || [];
  }

  get currentSchoolId() {
    return this.localEventsComponent.currentSchoolId;
  }

  set currentSchoolId(val:any) {
    this.localEventsComponent.currentSchoolId = val;
  }

  get isMenuOverlapping() {
    return this.localEventsComponent.isMenuOverlapping || this.emptyFunction;
  }
}
